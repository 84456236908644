import { RichTextField } from '@prismicio/client';
import classNames from 'classnames';
import PrismicRichText from 'components/PrismicRichText';

import styles from './SectionHeader.module.scss';

export const SectionHeader = ({
  title,
  desktopAlignment,
  mobileAlignment,
  underline,
}: {
  title: RichTextField;
  desktopAlignment: 'LEFT' | 'CENTER' | null;
  mobileAlignment: 'LEFT' | 'CENTER' | null;
  underline: 'NONE' | 'COLORED' | 'GREY' | null;
}) => (
  <div
    className={classNames('p--serif', {
      'd-flex fd-column ai-center': desktopAlignment === 'CENTER',
      [styles.mobileCenter]: mobileAlignment === 'CENTER',
    })}
  >
    <PrismicRichText richText={title} />
    <div
      data-aos="animate-underline"
      data-aos-once="true"
      className={classNames({
        [styles.separator]: underline === 'COLORED',
        [styles.separatorGrey]: underline === 'GREY',
      })}
    />
  </div>
);
